import React from 'react';
import Header from '../header/header';
import '../../assets/styles/layout.css';
import { MDXProvider } from '@mdx-js/react';
import { ErrorBoundary } from '../error-boundary';
import '../../assets/styles/fonts.css';
import { components } from './mdx-components';
import { PageFooter } from './page-footer';
import { NewsletterForm } from '../newsletter-form/newsletter-form';

const SimpleLayout: React.FC = ({ children }) => (
  <div className="flex flex-col justify-between min-h-screen">
    <ErrorBoundary scope="layout">
      <React.StrictMode>
        <MDXProvider components={components}>
          <ErrorBoundary scope="page-header">
            <Header />
          </ErrorBoundary>
          <div className="w-full max-w-screen-lg mx-auto lg:pt-8">
            <ErrorBoundary scope="page-content">
              <main className="p-4 lg:p-6">{children}</main>
            </ErrorBoundary>
          </div>
          <NewsletterForm />
          <PageFooter />
        </MDXProvider>
      </React.StrictMode>
    </ErrorBoundary>
  </div>
);

export default SimpleLayout;
