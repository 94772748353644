import React from 'react';
import { graphql, Link } from 'gatsby';
import { withErrorBoundary } from '../components/error-boundary';
import { TextLink, TextLinkVariant } from '../components/design';
import { HeroElement } from '../components/layouts';
import HeroLayout from '../components/layouts/hero-layout';
import SEO from '../components/seo';
import { PostCardContainer } from '../components/post-card/post-card-container';
import { PostCard } from '../components/post-card';
import { Alert, AlertVariant } from '../components/alert';

export interface CategoryTemplateProps {
  pageContext: {
    count: number;
    category: string;
  };
  data: any; // FIXME TODO
}

export const pageQuery = graphql`
  query ($category: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category }, hidden: { ne: true } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMM YYYY")
            description
            tags
            category
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 48, height: 48)
              }
            }
          }
        }
      }
    }
  }
`;

export const CategoryTemplate: React.FC<CategoryTemplateProps> = withErrorBoundary({
  scope: 'category-template',
})(({ pageContext, data }) => {
  const { category, count } = pageContext;
  const { edges } = data.allMdx;
  const hero = (
    <HeroElement
      title={category}
      description={`A collection of ${count} post${count > 1 ? 's' : ''}`}
    >
      <TextLink as={Link} to="/blog/categories" variant={TextLinkVariant.ACCENT}>
        See all categories
      </TextLink>
      {' or '}
      <TextLink as={Link} to="/blog" variant={TextLinkVariant.ACCENT}>
        go to all posts
      </TextLink>
    </HeroElement>
  );

  return (
    <HeroLayout hero={hero}>
      <SEO title={`Blog | ${category}`} description={`Posts in ${category} category`} />
      {edges.length > 0 ? (
        <PostCardContainer>
          {edges.map(
            ({
              node: {
                id,
                frontmatter,
                fields: { slug },
              },
            }: any) => (
              <PostCard key={id} {...frontmatter} path={slug} />
            ),
          )}
        </PostCardContainer>
      ) : (
        <Alert title="No posts were found for this category" variant={AlertVariant.INFO} />
      )}
    </HeroLayout>
  );
});
CategoryTemplate.displayName = 'CategoryTemplate';
export default CategoryTemplate;
