import React, { ReactElement, ReactNode } from 'react';

interface HeroElementProps {
  title: ReactNode;
  description?: string;
  children?: ReactNode;
}

export const HeroElement = ({ title, description, children }: HeroElementProps): ReactElement => (
  <header className="text-white">
    <h1 className="font-sans text-4xl sm:text-5xl my-4 px-0 text-white">{title}</h1>
    {description && <p className="mt-3 mb-5">{description}</p>}
    {children}
  </header>
);
