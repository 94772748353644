import React, { ReactElement, ReactNode } from 'react';
import Header from '../header/header';
import '../../assets/styles/layout.css';
import { MDXProvider } from '@mdx-js/react';
import { ErrorBoundary } from '../error-boundary';
import '../../assets/styles/fonts.css';
import { components } from './mdx-components';
import { PageFooter } from './page-footer';

interface HeroLayoutProps {
  children: ReactNode;
  hero: ReactNode;
}

const HeroLayout = ({ children, hero }: HeroLayoutProps): ReactElement => (
  <ErrorBoundary scope="layout">
    <React.StrictMode>
      <MDXProvider components={components}>
        <ErrorBoundary scope="page-header">
          <Header flatWhite />
        </ErrorBoundary>
        <ErrorBoundary scope="hero-element">
          <section className="bg-main">
            <div className="max-w-screen-lg mx-auto py-5 sm:py-16 px-4 lg:px-6 text-white">
              {hero}
            </div>
          </section>
        </ErrorBoundary>
        <div className="w-full max-w-screen-lg mx-auto lg:pt-8">
          <ErrorBoundary scope="page-content">
            <main className="p-4 lg:p-6">{children}</main>
          </ErrorBoundary>
        </div>
        <PageFooter />
      </MDXProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

export default HeroLayout;
