import React, { ReactElement, useEffect } from 'react';
import { H2 } from '../design';
import * as styles from './newsletter-form.module.css';

const ID = 'newsletter-form';

export const NewsletterForm = (): ReactElement => {
  useEffect(() => {
    window.hbspt.forms.create({
      region: 'na1',
      portalId: '19528362',
      formId: '785d5dbc-3f8c-4d0e-b33a-184a2a3ad40d',
      target: `#${ID}`,
    });
  }, []);

  return (
    <section className="w-full bg-gray-100 text-gray-800">
      <div className="max-w-screen-lg mx-auto pb-8 px-6 text-center">
        <H2 fontFamily="serif">Stay connected</H2>
        <h3 className="text-xs text-gray-500 italic font-serif">
          Stay up to date with recent articles &mdash; subscribe to the newsletter.
          <br />
          Unsubscribe at any time with a link in the email.
        </h3>
        <div id={ID} className={`py-6 ${styles.FormWrapper}`} />
      </div>
    </section>
  );
};
