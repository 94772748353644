import React, { ReactNode } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MdxFrontmatter } from '../../generated/graphql.d';
import { InlineIcon } from '@iconify/react';
import clockCircleOutlined from '@iconify/icons-ant-design/clock-circle-outlined';
import lockTwotone from '@iconify/icons-ant-design/lock-twotone';

export interface PostCardProps extends Omit<MdxFrontmatter, 'title'> {
  title: ReactNode;
  premium?: boolean;
  path: string;
  timeToRead?: number;
}

export const PostCard: React.FC<PostCardProps> = ({
  title,
  path,
  description,
  date,
  thumbnail,
  premium,
  category,
  timeToRead,
}) => {
  const thumbnailImage = thumbnail?.childImageSharp?.gatsbyImageData;
  return (
    <Link to={path!} className="flex flex-col hover:shadow-lg rounded-lg border">
      <header className="h-36 border-b px-4 relative">
        {category && <div className="absolute mt-2 text-xs text-main font-medium">{category}</div>}
        <div className="flex h-full">
          {thumbnailImage && (
            <div className="block my-auto">
              <GatsbyImage image={thumbnailImage} alt="Post icon" aria-hidden />
            </div>
          )}
          <span className="font-medium font-sans text-lg my-auto pl-3">{title}</span>
        </div>
      </header>
      <div className="flex-1 flex flex-col justify-between px-4 py-3 bg-gray-100 text-gray-700">
        <p className="text-sm mb-3 font-body">{description}</p>
        <footer className="text-gray-500 text-xs">
          {date && (
            <>
              <InlineIcon icon={clockCircleOutlined} className="mr-2 inline" />
              {date}
            </>
          )}
          {timeToRead && <> &bull; {Math.round(timeToRead)} minutes read</>}
          {premium && (
            <>
              &bull;
              <InlineIcon icon={lockTwotone} className="mr-2 inline" /> Members only
            </>
          )}
        </footer>
      </div>
    </Link>
  );
};
